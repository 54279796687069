<template>
  <div v-if="isAdminUser">
    <a-tabs :activeKey="activeName" v-model="activeName" @tab-click='handleClick'>
      <a-tab-pane key="1" tab="数据统计">
        <statistics :isAdminUser="isAdminUser" :userOptions="userOptions" :amountOption="amountOption"/>
      </a-tab-pane>
      <a-tab-pane key="2" tab="接口文档">
        <!-- <interfaces/>  -->
      </a-tab-pane>

    </a-tabs>
  </div>
</template>
<script>
import interfaces from './ASRabilitys/interfaces.vue'
import statistics from './ASRabilitys/statistics.vue'
import _lineService from "@/api/open/LineQualityInspections";

export default {
  data() {
    return {
      amountOption: [],
      userOptions: [],
      isAdminUser: '',
      activeName: '1'
    };
  },
  methods: {
    async handleClick(tab) {

      console.log(tab, '99999995555')

      if (tab == '2') {
        window.open('https://duofangai.com/#/official-website/markdown')
        this.activeName = '1'
      }
    },
    async getAmountList() {
      const res = await _lineService._recordService.getAmountList()
      this.amountOption = res?.data?.map(item => {
        return {label: item.name, value: item.id}
      }) || [];
    },
    async getUserOptions() {
      const res = await _lineService._recordService.queryList()
      this.userOptions = res.data.map(item => {
        return {label: item.nickname, value: item.uid}
      })
      console.log(res)
    },
    async getUserType() {
      const res = await _lineService._recordService.getUserType()
      this.isAdminUser = res?.data
    }
  },
  created() {
    this.getUserType()
    this.getAmountList()
    this.getUserOptions()
  },
  components: {
    interfaces,
    statistics
  }
};
</script>